import React from "react";

export default function piratesPage() {
    return (
        <>
            <h1 className="mark">Guest Profiles</h1>
            <br />
            <h2 className="mark">Tom Erik Project</h2>
            <br />
            <h2 className="mark">Guest Project 2</h2>
            <br />
            <h2 className="mark">Guest Project 3</h2>
        </>
    );
};